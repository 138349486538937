window.addEventListener('load', () => {


  // if ('serviceWorker' in navigator) {
  //   console.log('Found serviceWorker');
  //   navigator.serviceWorker.register('/sw.js')
  //   .then((reg) => {
  //     console.log('Service Worker Registered', reg);
  //   });
  // } else {
  //   console.log('Not Found serviceWorker');
  // }



  setTimeout(() => {
    document.getElementsByTagName('body')[0].classList.add('active')
    setTimeout(() => {
      document.getElementsByClassName('js_slash_animate')[0].classList.add('active')
    }, 800);
  }, 600);

  // FAQ
  let Quest = document.getElementsByClassName('quest')
  for (let faqList = 0; faqList < Quest.length; faqList++) {
    Quest[faqList].onclick = () => {

      if (Quest[faqList].classList.contains('active')) {
        Quest[faqList].classList.remove('active')
      } else {
        Quest[faqList].classList.add('active')
      }
    } //end onclick

  }
  // END FAQ

  // add height to first view.
  if (window.matchMedia('(min-width: 968px)').matches) {
    document.getElementById('js_height').style.height = (window.innerHeight - document.getElementById('header').getBoundingClientRect().height) + 'px'
  }



  // scroll calc animations.
  let renderSync  = false
  let baseHeight  = window.innerHeight
  let scrollEvent = () => {
    if (!renderSync) {
      renderSync = false

      if (document.getElementById('feel-safe').getBoundingClientRect().top <= baseHeight / 2) {
        document.getElementById('feel-safe').classList.add('active')
      }
    }
  }
  document.addEventListener('scroll', scrollEvent, {passive: true})
  // end scroll calc animations.

  /*
  // async processing
  let promise = new Promise((resolve, reject) => {
    setTimeout(() => {
      // console.log('done01');
      resolve()
    }, 1000)
  }).then(() => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // console.log('done02');
        resolve()
      }, 1000)
    })
  }).then(() => {
    // return new Promise((resolve, reject) => {
      setTimeout(() => {
        // console.log('done03');
        // resolve()
      }, 1000)
    // })
  })
})
*/

}) //onload
